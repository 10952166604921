<template>
  <order-table :target="target" :collection="collection">
    <template v-slot:header>
      <tr>
        <th style="width:40%;">Filename</th>
        <th style="width:10%;">Size</th>
        <th style="width:20%;">Created by</th>
        <th style="width:20%;">Created at</th>
        <th style="width:10%;">Actions</th>
      </tr>
    </template>

    <template v-slot:row="child">
      <td>{{ child.record.filename }}</td>
      <td>{{ child.record.human_size }}</td>
      <td>{{ child.record.user_role }}</td>
      <td>{{ child.record.created_at }}</td>
      <td>
        <a
          v-if="allowDownload(child.record)"
          class="btn btn-outline-primary btn-sm"
          target="blank"
          :href="renderDownloadLink(child.record)"
          >Download
        </a>
        <div v-if="child.record.rating" class="mt10">
          Rating: {{child.record.rating.moderator_average}}
        </div>
        <div class="mt10" v-if="isRating && child.record.rateable">
          <span v-if="child.record.rating">
            <a class="btn btn-gradient-success btn-sm mt5" :href="renderRateLink(child.record.id)">View</a>
          </span>
          <span v-else>
            <a class="btn btn-gradient-success btn-sm" :href="renderRateLink(child.record.id)">Rate</a>
          </span>
        </div>
      </td>
    </template>
  </order-table>

  <div class="mt-4" v-if="canUpdate">
    <uploader-app :params="uploaderParams" :onUpload="onUpload" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { format } from 'date-fns'
import { mapMutations, mapGetters } from 'vuex'

import OrderTable from 'src/widgets/order_table_app'
import UploaderApp from 'src/widgets/uploader_app'

export default defineComponent({
  props: {
    target: {
      type: String
    },
    collection: {
      type: String
    },
    klass: {
      type: String
    },
    rating: {
      type: String
    }
  },
  components: {
    OrderTable,
    UploaderApp
  },
  computed: {
    isRating() {
      return this.rating == "true" || this.canUpdate
    },
    uploaderParams() {
      return {
        target: this.target,
        klass: this.klass
      }
    },
    ...mapGetters('orders', {
      canDownload: 'canDownload',
      canUpdate: 'canUpdate'
    })
  },
  methods: {
    ...mapMutations('orders', [
      'createRecord'
    ]),
    onUpload(file, data) {
      const record = this.buildAsset(file, data)
      this.createRecord({association: this.collection, record})
    },
    buildAsset(file, data) {
      const id = file.meta['x-amz-meta-asset-id'] || file.id;

      return {
        id: id,
        byte_size: file.size,
        created_at: format(new Date(), 'dd MMM HH:mm'),
        filename: file.name,
        human_size: file.size,
        url: data.uploadURL,
        user_role: 'RemoteQa'
      }
    },
    renderRateLink(asset_id) {
      return `/orders/${this.target}/ratings/${asset_id}/edit`
    },
    renderDownloadLink(asset) {
      if (asset.public_token) {
        return `/downloads/${asset.public_token}`;
      }

      return asset.url;
    },
    allowDownload(asset) {
      return asset.public_token || asset.url;
    }
  }
})
</script>
