<template>
  <div>
    <slot name="button" :onClick="toggleModal">
      <button @click="toggleModal" class="btn btn-sm btn-outline-primary">
        + Upload files
      </button>
    </slot>

    <dashboard-modal
      :uppy="uppy"
      :open="open"
      :props="dashboard"
      :plugins="plugins"
      :onRequestCloseModal="handleClose"
    />
  </div>
</template>

<script lang="ts">
import { csrfToken } from "@rails/ujs";
import { defineComponent } from "vue";
import { DashboardModal } from "@uppy/vue";
import Webcam from "@uppy/webcam";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

const onBodyBuilder = (file, params) => {
  return {
    filename: file.name,
    type: file.type,
    size: file.size,
    provider: "s3",
    assetable_id: params.target,
    klass: params.klass,
  };
};

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    endpoint: {
      type: String,
      default: "/endpoints",
    },
    dashboard: {
      type: Object,
      default() {
        return {
          theme: "light",
          inline: false,
          closeModalOnClickOutside: true,
          disablePageScrollWhenModalOpen: false,
        };
      },
    },
    params: Object,
    config: {
      type: Object,
      default() {
        return {
          restrictions: {
            maxTotalFileSize: 50_000_000,
          },
        };
      },
    },
    onUpload: {
      type: Function,
    },
    onComplete: {
      type: Function,
    },
    onBodyBuilder: {
      type: Function,
      default: onBodyBuilder,
    },
    plugins: {
      type: Array,
    },
  },
  data() {
    return {
      open: this.isOpen,
    };
  },
  components: {
    DashboardModal,
  },
  computed: {
    uppy() {
      return new Uppy(this.config).use(Webcam).use(AwsS3, {
        limit: 2,
        companionUrl: this.endpoint,
        getUploadParameters: this.getUploadParameters,
      });
    },
  },
  mounted() {
    this.uppy.on("dashboard:modal-closed", this.handleClose);
    this.uppy.on("upload-success", this.handleUpload);
    this.uppy.on("complete", this.handleComplete);
  },
  beforeUnmount() {
    this.uppy.close();
  },
  methods: {
    toggleModal(event) {
      this.open = true;

      if (event) {
        event.preventDefault();
      }

      return false;
    },
    handleClose() {
      this.open = false;
    },
    handleUpload(file, data) {
      if (typeof this.onUpload === "function") {
        this.onUpload(file, data, this.uppy);
      }
    },
    handleComplete(result) {
      if (typeof this.onComplete === "function") {
        this.onComplete(result, this.uppy);
      }
    },
    getUploadParameters(file) {
      const token = csrfToken();
      const body = JSON.stringify(this.onBodyBuilder(file, this.params));

      return fetch(this.endpoint, {
        method: "post",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          "X-CSRF-Token": token,
        },
        body: body,
      })
        .then((response) => {
          // Parse the JSON response.
          return response.json();
        })
        .then((data) => {
          const fields = JSON.parse(data.headers);

          return {
            method: data.requestMethod,
            url: data.url,
            fields: fields,
          };
        });
    },
  },
});
</script>
